@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /*------Color variables------*/
    --black_900: #000000;
    --black_900_4c: #0000004c;
    --black_900_7f: #0000007f;
    --blue_500: #1e90ff;
    --blue_gray_100: #cccccc;
    --gray_200: #eeeeee;
    --gray_400: #bbbbbb;
    --gray_900: #232323;
    --gray_900_01: #181818;
    --red_a700: #ff0000;
    --white_a700: #ffffff;

    /*------Shadow variables------*/
    --shadow-xs: 0 10px 20px 0 #0000004c;

    /*------Border radius variables------*/
    --radius-xs: 14px;
    --radius-sm: 24px;

    /*------Spacing variables------*/
    --space-xs: 4px;
    --space-sm: 10px;
    --space-md: 16px;
    --space-lg: 20px;
    --space-xl: 24px;
    --space-2xl: 50px;
  }
}
@layer components {
  /*-------------------------------------
  Container CSS
--------------------------------------*/
  .container-xs {
    @apply w-full mx-auto max-w-[1200px];
  }
}
