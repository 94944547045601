body {
  margin: 0;
  padding: 0;
  font-family: Poppins;
  background-color: #181818;
}

* {
  box-sizing: border-box;
  line-height: normal;
  font-family: inherit;
  margin: unset;
}
